import React from 'react';
import HeroScroll from '../Hero/HeroScroll';
import {HeroColorMode, HeroProps, HeroState, HeroTextPosition} from './types';
import VideoControls from '../VideoControls';
import debounce from 'lodash/debounce';

export const isBrowser = typeof window !== 'undefined';

class Hero extends React.Component<HeroProps, HeroState> {
    ref: HTMLDivElement | null | undefined;
    
    videoRef: React.RefObject<HTMLVideoElement>;

    static defaultProps = {
        parallax: false,
        wave: '',
    }

    constructor(props: HeroProps) {
        super(props)
        this.state = {
            height: 0,
            isMounted: false,
            playing: true,
        }

        this.handleResize = this.handleResize.bind(this);
        this.updateSize = debounce(this.updateSize.bind(this), 500);

        this.videoRef = React.createRef();
    }

    componentDidMount() {
        this.loadImage();
        setTimeout(() => {
            this.setState({isMounted: true});
        })

        window.addEventListener('resize', this.handleResize);

        this.updateSize();
    }

    componentDidUpdate(prevProps: HeroProps) {
        if (prevProps.bgImageSrc !== this.props.bgImageSrc) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.updateSize();
    }

    updateSize() {
        if (!this.ref) {
            return;
        }

        this.setState({height: this.ref.offsetHeight});
    }

    loadImage() {
        if (!this.props.bgImageSrc) {
            return;
        }

        const image = new Image();
        image.src = this.props.bgImageSrc;
        image.onload = () => {
            image.onload = () => {
                this.setState({}, () => {
                    this.updateSize();
                });
            };
        }
    }

    render() {
        const {height} = this.state;
        const hasChildren =
            React.Children.toArray(this.props.children).filter(child => !!child).length > 0;
        return (
            <div
                className={`hero ${this.props.colorMode === HeroColorMode.Dark ? 'dark' : ''} ${hasChildren ? 'hasChildren' : ''} ${this.props.noMargin ? 'noMargin' : ''} ${this.props.wave === 'bottom' ? 'wave-bottom' : ''} ${this.state.isMounted ? 'isMounted' : ''}`}
                ref={r => {
                    this.ref = r
                }}
            >
                {(this.props.bgImageSrc || this.props.bgVideoSrc) && (
                    <div className={`hero__scroll-container ${this.props.wave === 'bottom' ? 'wave-bottom' : ''}`}
                         style={{height: height + 'px'}}>
                        <HeroScroll
                            parallax={this.props.parallax}
                            overlay={false}
                        >
                            {this.props.bgImageSrc && (
                                <div className={`hero__cover bgImage`}
                                     style={{backgroundImage: `url(${this.props.bgImageSrc})`}}/>
                            )}
                            {this.props.bgVideoSrc && (
                                <div className={'hero__cover'}>
                                    <video
                                        ref={this.videoRef}
                                        poster={
                                            this.props.bgImageSrc || undefined
                                        }
                                        aria-label={this.props.altText || ''}
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                    >
                                        <source
                                            src={this.props.bgVideoSrc}
                                            type={
                                                this.props.bgVideoType ||
                                                'video/mp4'
                                            }
                                        />
                                    </video>
                                </div>
                            )}
                        </HeroScroll>
                    </div>
                )}
                {this.props.bgVideoSrc && (
                    <VideoControls
                        ref={this.videoRef}
                        className="hero_vid_ctrls"
                    />
                )}

                {this.props.bgVideo}
                {this.props.bgImage}
                {hasChildren && (
                    <div className={`hero__inner ${this.props.wave === 'bottom' ? 'wave-bottom' : ''}
                    ${this.props.textPosition === HeroTextPosition.Right ? 'right' : 'left'}
                    ${hasChildren ? 'hasChildren' : ''}
                    `}>
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}

export default Hero;
